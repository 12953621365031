<template>
    <div class="relative w-full flex flex-col items-center justify-start">
        <div class="w-full h-full absolute inset-0 z-1">
            <NuxtImg
                :src="getThumbnail(banner, 2000, true)"
                width="2000"
                sizes="sm:100vw md:50vw lg:2000px "
                loading="lazy"
                alt="Banner Collection Detail"
                :placeholder="[50, 25, 75, 5]"
                class="w-full h-full object-cover"
            />
        </div>
        <div class="absolute inset-0 z-2 bg-overlay-01"></div>
        <div class="container relative z-10 py-20 lg:py-27">
            <div class="w-full">
                <div class="w-full">
                    <GlobalBreadcrumb :breadcrumb="breadcrumb" />
                    <div
                        ref="js_heading_collection"
                        class="heading_collection text-36px lg:text-48px text-white font-medium leading-15"
                        v-html="props.title"
                    ></div>
                    <div
                        v-html="props.des"
                        ref="js_sub_heading_collection"
                        class="sub_heading_collection lg:text-lg font-medium text-white mt-5"
                    ></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { convert } from 'html-to-text'
const props = defineProps({
    banner: {
        type: String
    },
    title: {
        type: String
    },
    des: {
        type: String
    }
})

const convertedTitle = convertNewlinesToBr(props.title)
const convertedDes = convertNewlinesToBr(props.des)
const breadcrumb = ref([
    {
        title: 'Trang chủ',
        url: '/'
    },
    {
        title: 'Bộ sưu tập',
        url: '/bo-suu-tap'
    },
    {
        title: convert(props.title)
    }
])
const js_heading_collection = ref<HTMLElement>([])
const js_sub_heading_collection = ref<HTMLElement>([])

onMounted(() => {
    const wordsHeading = js_heading_collection.value.innerHTML
    const wordsSubHeading = js_sub_heading_collection.value.innerHTML

    js_heading_collection.value.innerHTML = ''

    js_sub_heading_collection.value.innerHTML = ''
    document.querySelectorAll('.heading_collection').forEach((item: any) => {
        item.classList.add('active')
    })
    document.querySelectorAll('.sub_heading_collection').forEach((item: any) => item.classList.add('active'))
    wordsHeading.split(/(<\/>)/).forEach(function (word) {
        const div = document.createElement('div')
        div.innerHTML = word + ' '
        js_heading_collection.value.appendChild(div)
        js_heading_collection.value.querySelectorAll('div').forEach(function (div, index) {
            div.classList.add('inline', 'leading-25px')

            div.style.animation = `fade-in-heading 0.8s ${0.1 * index}s forwards cubic-bezier(0.11, 0, 0.5, 0)`
        })
    })
    wordsSubHeading.split(/(<\>)/).forEach(function (word) {
        const div = document.createElement('div')
        // console.log('🚀 ~ file: SectionBanner.vue:91 ~ div:', div)
        div.innerHTML = word + ' '
        js_sub_heading_collection.value.appendChild(div)
        js_sub_heading_collection.value.querySelectorAll('div').forEach(function (div, index) {
            div.querySelectorAll('p , li').forEach(function (content, index) {
                content.textContent.split(' ').forEach((word: string) => {
                    const span = document.createElement('span')
                    span.innerText = word + ' '
                })
                // content.parentNode.replaceChild(blockAnimation, content)
            })
            div.classList.add('inline', 'leading-25px', 'sub-heading-collection-custom')

            div.style.animation = `fade-in-heading 2s ${0.1 * index}s forwards cubic-bezier(0.11, 0, 0.5, 0)`
        })
    })
})
</script>

<style>
.sub-heading-collection-custom li {
    list-style-type: disc;
}
</style>
