<template>
    <SectionBanner
        :banner="collectionDetail.banner"
        :title="collectionDetail?.title"
        :des="collectionDetail?.description"
    />
    <SectionListFilter :filter="filter" :keyFilter="'collection'" :urlModel="collectionDetail?.url" />
    <SectionSlideBottom :listImage="collectionDetail?.images" />
    <SectionDecorBottom />
</template>

<script setup lang="ts">
definePageMeta({
    layout: 'custom'
})
import SectionBanner from './SectionBanner.vue'
import SectionListFilter from './SectionListFilter.vue'
import SectionSlideBottom from './SectionSlideBottom.vue'
import SectionDecorBottom from './SectionDecorBottom.vue'
import { convert } from 'html-to-text'
const globalSettings = inject('globalSettings')
const slug = useRoute().params.slug
const { getProductCollections, getProductFilterData } = useProducts(globalSettings)
const collectionDetail = await getProductCollections({ slug: slug })
const filter = await getProductFilterData({ collection: { slug: slug } })
console.log('🚀 ~ collectionDetail?.seo_detail:', collectionDetail, collectionDetail?.seo_detail)

useDirectusCollectionSeo('product_collections', {
    ...collectionDetail,
    title: convert(collectionDetail.title),
    seo_detail: {
        ...collectionDetail?.seo_detail
    }
})
</script>

<style></style>
