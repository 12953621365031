<template>
    <section v-if="listImage && listImage.length > 0" class="bg-[#F8F8F8]">
        <div class="py-10 md:py-15 xl:py-24">
            <Swiper
                :modules="[SwiperAutoplay, SwiperNavigation]"
                :slides-per-view="1.4"
                :centeredSlides="true"
                :roundLengths="true"
                :breakpoints="{
                    300: { spaceBetween: 15 },
                    1020: { spaceBetween: 48 }
                }"
                :navigation="{
                    nextEl: '.next-swiper-collection',
                    prevEl: '.prev-swiper-collection'
                }"
                :loop="true"
                :loopAdditionalSlides="30"
                :speed="500"
                :grabCursor="true"
                @swiper="initSwiper"
                class="w-full aspect-16/7 lg:h-[calc(100vh-208px)] xl:h-[calc(100vh-280px)]"
            >
                <SwiperSlide
                    v-for="item in listImage"
                    class="swiper-collection relative w-full h-full !flex !items-center"
                >
                    <div class="absolute inset-0">
                        <div class="w-full h-full">
                            <NuxtImg
                                :src="getThumbnail(item, 1000, true)"
                                width="1000"
                                sizes="sm:100vw md:50vw lg:1000px "
                                loading="lazy"
                                alt="Banner Collection Detail"
                                :placeholder="[50, 25, 75, 5]"
                                class="w-full h-full object-cover"
                            />
                        </div>
                    </div>
                    <div
                        v-if="listImage && listImage.length > 1"
                        class="relative z-10 swiper-navigation-collection flex items-center justify-between w-full"
                    >
                        <button
                            class="prev-swiper-collection w-8 h-8 lg:w-12 lg:h-12 text-white flex flex-col items-center justify-center bg-blacks-100 hover:bg-blacks-100/70 duration-300"
                        >
                            <span class="i-ic:sharp-keyboard-arrow-left text-2xl"></span>
                        </button>
                        <button
                            class="next-swiper-collection w-8 h-8 lg:w-12 lg:h-12 text-white flex flex-col items-center justify-center bg-blacks-100 hover:bg-blacks-100/70 duration-300"
                        >
                            <span class="i-ic:sharp-keyboard-arrow-right text-2xl"></span>
                        </button>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    </section>
</template>

<script setup lang="ts">
defineProps({
    listImage: {
        type: Array
    }
})
const initSwiper = (event) => {
    event.slideNext()
}
</script>

<style>
.swiper-collection .swiper-navigation-collection {
    @apply hidden;
}
.swiper-collection.swiper-slide-active .swiper-navigation-collection {
    @apply flex;
}
</style>
